import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Sections from "../../components/sections"

export const query = graphql`
  query PartnerQuery($slug: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }

    strapiPartner(slug: { eq: $slug }) {
      id
      name
      slug
      seo {
        keywords
        metaDescription
        metaTitle
        robots
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      sections {
        strapi_component
        title
        subtitle
        content
        background
        action {
          label
          newWindow
          url
          primary
        }
        lead_capture {
          buttonLabel
          title
          type
        }
        review_items {
          content
          author
        }
        media_url
        feature_items {
          content
          imagePosition
          title
        }
        faq_items {
          answer
          question
        }
      }
    }
  }
`

const Partner = props => {
  const { strapiGlobal, strapiPartner } = props.data

  return (
    <Layout seo={strapiPartner.seo} strapiGlobal={strapiGlobal}>
      <Sections
        sections={strapiPartner.sections}
        path={props.path}
        isHomePage={props.path === "/"}
      />
    </Layout>
  )
}

export default Partner
